.page-content {
  padding: 50px 40px 40px 0px;
}

.custom-btns {
  background-color: #F0F0F0;
  display: inline-block;
  border: none;
  margin-right: 5px;
  color: #74788D;
}

.pagination-number {
  border-radius: 50%;
  color: #74788D;
  background-color: #F0F0F0;
  width: 35px;
  height: 35px;
}

.speed-btn {
  color: #000;
  background-color: #F0F0F0;
  width: auto;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
}

.commentBody {
  background-color: #fff;
  padding: 15px;
  color: #74788D;
}

.commentsList li {
  margin-bottom: 10px;
}

.eavluationTabs .active {
  border-bottom: 3px solid #00BA52;
}

.eavluationTabs {
  border-bottom: 1px solid #B2B7BC;
}

.nav .nav-link {
  color: #212529;
}

.vid-overlay {
  opacity: 0.6;
  position: absolute;
  bottom: 32px;
  right: 7px;
  z-index: 1000;
  background-color: #33333340;
  padding: 4px;
}

.bg-green {
  background-color: #00BA52;
}

.btn-link {
  color: #00BA52 !important;
  text-decoration: none;
}

.alertSuccess {
  color: #00BA52;
  background-color: #00BA5207;
  border: 1px solid #00BA52 !important;
  border-radius: 10px !important;
}