.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

body {
  background-color: #f8f8fb !important;
}

.card {
  box-shadow: 0px 4px 8px -4px #b0b0b0;
  border: none !important;
  border-radius: 12px !important;
}

span {
  font-size: 0.7em;
}

h6 {
  font-size: 0.75em !important;
  font-weight: 600;
}

.navbar-dark {
  background-color: #001c35 !important;
}

.green {
  color: #00BA52;
}