.login-page {
  background-color: #a5a5a520;
}

.login-card {
  height: 100vh;
}

.btn-primary {
  background-color: #00BA52 !important;
  border: #00BA52 !important;
}

.btn-primary:hover {
  background-color: #02aa4b;
  border: #02aa4b;
}